  import React from 'react'
  import Layout from '../../../../components/App/Layout'
  import Navbar from '../../../../components/App/NavbarRV'
  import Footer from '../../../../components/App/FooterRV'
  import KDPImagePage from '../../../../assets/images/pages/2-2-3-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/expertise-assure/'>Expertise d'assuré</a></li><li><a href='/expertise-assure/expert-assure/'>L'expert d'assuré</a></li><li>Déroulement de la mission</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Comment se déroule une mission d’expertise ? </h1>

<p>À réception du mandat de nomination d’Expert, une mission d’expertise se déroule selon les étapes suivantes :</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Déroulement de la mission" />
</div>


<ol><li>analyse des pièces constitutives de votre dossier : Par mail, l’Expert réceptionne certaines pièces lui permettant de mener à bien son expertise. L’expert recueille au préalable des éléments photographiques, plans, plan de masse, contrat, contrat d’assurance. </li>

<li>Visite technique sur place : votre Expert RV EXPERTISES fixe un rdv avec vous pour une visite technique <i>in situ</i>. Cette étape est indispensable afin de pouvoir constater visuellement les désordres, apprécier l’ouvrage et son environnement afin de répondre au mieux aux missions de l’expertise.</li>
<li>Remise du rapport : Au terme de ses investigations, l’expert compile les éléments constatés et relevés sur place, procède à des analyses en bureau qu’il synthétise dans un rapport clair, concis et répondant aux objectifs de la mission qui lui a été confiée.</li></ol>

<h2>Qui peut mandater un Expert d’assuré ? </h2>

<p>Tous les acteurs de la construction peuvent mandater un Expert :</p>
<ul><li>le particulier,</li>
<li>une entreprise,</li>
<li>un syndicat d’immeuble,</li>
<li>une association syndicale,</li>
<li>un Maître d’oeuvre.</li></ul>



<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i>Contacter un Expert d’assuré</a>
    </div>
</div>




                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/expertise-assure/expert-assure/mission/' className='active'>Déroulement de la mission</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details